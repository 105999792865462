<template>
  <header role="banner" class="page-header">
    <div class="page-header__content">
      <div class="page-header__navigation">
        <HeaderNavigation />
      </div>
      <div class="page-header__logo">
        <NuxtLink :to="{ name: 'index' }">
          <WeidwerkLogo />
        </NuxtLink>
      </div>
      <div class="page-header__icons">
        <NuxtLink :to="{ name: 'search' }" class="page-header__search-container" @click.native="searchClicked()">
          <Search />
        </NuxtLink>
      </div>
    </div>
  </header>
</template>

<script>
import HeaderNavigation from '@/components/Header/HeaderNavigation'
import WeidwerkLogo from '@/components/UserInterface/Icon/weidwerk-logo-dark.svg'
import Search from '@/components/UserInterface/Icon/icon-search.svg'

export default {
  components: {
    HeaderNavigation,
    WeidwerkLogo,
    Search,
  },
  methods: {
    searchClicked() {
      this.$fb.query('trackCustom', 'search-clicked')
    },
  },
}
</script>

<style lang="scss">
@import 'assets/scss/import/vars';

.page-header {
  position: relative;
  z-index: 20;
  background-color: $color-white;

  &__content {
    height: 50px;
    box-shadow: 0 1px 0 0 $color-gray-light;
    max-width: $max-width-content;
    margin: auto;
    display: flex;
    align-items: center;
    padding: 0 15px;

    @media (min-width: $screen-width-767) {
      height: 100px;
    }

    @media (min-width: $screen-width-1300 + 50px) {
      padding: 0;
    }
  }

  &__navigation,
  &__logo,
  &__icons {
    flex: 1;
    display: flex;
  }

  &__navigation {
    justify-content: flex-start;
  }

  &__logo {
    justify-content: center;

    a {
      display: flex;
    }
  }

  &__icons {
    align-items: center;
    justify-content: flex-end;
  }

  &__search-container {
    display: flex;
  }

  @media (min-width: $screen-width-767) {
    &__logo {
      svg {
        height: 50px;
        width: 222px;
      }
    }
  }

  @media (min-width: $screen-width-1023) {
    &__logo {
      flex: 3;
      justify-content: flex-start;
    }

    &__navigation {
      flex: 9;
      order: 1;
    }

    &__icons {
      flex: 2;
      order: 2;
    }
  }
}
</style>
