import _slugify from 'slugify'
_slugify.extend({
  ö: 'oe',
  Ö: 'Oe',
  ä: 'ae',
  Ä: 'Ae',
  ü: 'ue',
  Ü: 'Ue',
  ß: 'ss',
  ẞ: 'SS',
})

export function slugify(s) {
  return encodeURIComponent(
    _slugify(s, {
      lower: true,
      replacement: '-',
      remove: /[^A-Za-z0-9- ]/g,
    })
  )
}

/**
 * @description Reduce channels data structure to one channel filtered by
 * portalId.
 * @param {Array} channels - List of channel objects
 * @returns {Object, null}
 */
export function getPortalChannel(channels = [], $config) {
  return channels.reduce((result, c) => {
    if (c.relationships && c.relationships.portal && c.relationships.portal.id) {
      const portalId = parseInt(c.relationships.portal.id)
      if (portalId === $config.portalId) {
        return c
      }
    }
    return result
  }, null)
}

export function parseChannelSlugs(channels = [], $config) {
  // Reduce Channels by portalId
  const channel = getPortalChannel(channels, $config)
  if (channel === null) return []
  const parentSlugs = channel.relationships.parents.map((c) => c.attributes.slug).filter((slug) => !!slug)
  return parentSlugs.concat(channel.attributes.slug)
}

export default ({ app }, inject) => {
  inject('getPortalChannel', getPortalChannel)
  inject('parseChannelSlugs', parseChannelSlugs)
  inject('slugify', slugify)
}
