<template>
  <a :href="article.attributes.teaser.link" :target="article.attributes.teaser.linkTarget" class="link-article">
    <slot>
      {{ article.attributes.title }}
    </slot>
  </a>
</template>

<script>
export default {
  props: {
    article: {
      type: Object,
      required: true,
    },
  },
}
</script>
