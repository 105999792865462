<template>
  <NuxtLink :to="link" class="button-link">
    <slot />
  </NuxtLink>
</template>

<script>
export default {
  props: {
    link: {
      required: true,
      type: Object,
    },
  },
}
</script>

<style lang="scss">
@import 'assets/scss/import/mixins';

@include button-link;
</style>
