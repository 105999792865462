import Vue from 'vue'
import VueBehave from '@strg/behave-tracking-client-vue'

export default ({ app: { router }, $config }) => {
  const options = {
    debugMode: $config.behaveDebugMode,
    config: {
      ENDPOINT: $config.behaveEndpoint,
    },
  }

  Vue.use(VueBehave, { router, options })
}
