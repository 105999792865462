export default ({ $winstonLog }) => {
  global.$nuxtLogger = {
    error(message) {
      // eslint-disable-next-line no-console
      typeof window === 'undefined' ? $winstonLog.error(message) : console.error(message)
      return true
    },
    info(message) {
      // eslint-disable-next-line no-console
      typeof window === 'undefined' ? $winstonLog.info(message) : console.info(message)
      return true
    },
  }
}
