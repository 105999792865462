<template>
  <nav class="footer-navigation-category">
    <div class="footer-navigation-category__header" @click="toggleMenu">
      <h3 class="footer-navigation-category__title">
        <NavigationLink :nav-item="navCategory" />
      </h3>
      <IconChevron
        class="footer-navigation-category__more-items"
        :class="{
          'footer-navigation-category__more-items--expanded': isToggled,
        }"
      />
      <Divider class="footer-navigation-category__menu-divider" />
    </div>
    <Transition name="rolldown">
      <ul v-show="isToggled && isMobileView" role="menu" class="footer-navigation-category__items">
        <FooterNavigationItem
          v-for="(navItem, index) in navCategory.attributes.children"
          :key="index"
          :nav-item="navItem"
        />
      </ul>
    </Transition>
  </nav>
</template>

<script>
import Divider from '@/components/UserInterface/Icon/divider.svg'
import IconChevron from '@/components/UserInterface/Icon/icon-chevron-right-brown.svg'
import NavigationLink from '@/components/UserInterface/Link/NavigationLink'
import FooterNavigationItem from '@/components/Footer/FooterNavigationItem'

export default {
  components: {
    FooterNavigationItem,
    NavigationLink,
    IconChevron,
    Divider,
  },
  props: {
    navCategory: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isToggled: false,
    }
  },
  computed: {
    isMobileView() {
      const isMobile = this.$store.getters['screenSize/isMobile']
      return isMobile !== null ? isMobile : true
    },
  },
  methods: {
    toggleMenu() {
      this.isToggled = !this.isToggled
    },
  },
}
</script>

<style lang="scss">
@import 'assets/scss/import/vars';
@import 'assets/scss/import/mixins';

.footer-navigation-category {
  flex-basis: 25%;

  &:last-child {
    flex: 1;
    border-bottom: 1px solid $color-gray;

    @media (min-width: $screen-width-1023) {
      border-bottom: none;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid $color-gray;

    @media (min-width: $screen-width-1023) {
      display: block;
      padding-top: 88px;
    }
  }

  &__title {
    @include bold-source-sans-pro;

    color: $color-white;
    font-size: 18px;
    letter-spacing: 1.8px;
    text-transform: uppercase;
    margin: 0;
    line-height: 68px;

    @media (min-width: $screen-width-1023) {
      line-height: 26px;
    }

    a {
      color: $color-white;
    }
  }

  &__more-items {
    transition: all 400ms;

    @media (min-width: $screen-width-1023) {
      display: none;
    }

    &--expanded {
      transform: rotate(90deg);
    }
  }

  &__menu-divider {
    display: none;

    @media (min-width: $screen-width-1023) {
      display: block;
      margin: 24px 0 20px 0;
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    border-top: 1px solid $color-gray;
    overflow: hidden;
    max-height: 1000px;

    @media (min-width: $screen-width-1023) {
      display: block !important;
      border: none;
      max-height: unset;
    }
  }

  .rolldown-enter-active,
  .rolldown-leave-active {
    transition: max-height 400ms;
  }

  .rolldown-enter,
  .rolldown-leave-to {
    max-height: 0;
  }
}
</style>
