import { set } from 'vue'
import CHANNELS from '@/gql/channels.gql'

export const state = () => ({
  channels: null,
  isCached: false,
})

export const mutations = {
  SET(state, data) {
    set(state, 'channels', data)
    set(state, 'isCached', true)
  },
}

export const actions = {
  async fetch({ commit, state }) {
    if (state.isCached) return
    try {
      const client = this.app.apolloProvider.defaultClient
      const allChanelsData = await client.query({
        query: CHANNELS,
        variables: {
          portalId: this.$config.portalId,
        },
      })

      commit('SET', allChanelsData.data.getChannelsByPortalId.data)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  },
}

export const getters = {
  channelById({ channels }) {
    return (channelId) => {
      const channel = findChannelById(channels, channelId)

      if (channel === undefined) {
        // eslint-disable-next-line no-console
        console.error(`Could not find channel with ID: "${channelId}"`)
      }

      return channel
    }
  },
}

function findChannelById(channels, id) {
  let channel = channels.find((channel) => channel.id === id)

  if (channel === undefined) {
    let index = 0
    while (channel === undefined && index < channels.length) {
      channel = findChannelById(channels[index++].relationships.children, id)
    }
  }

  return channel
}
