<template>
  <Component :is="`ArticleLink${linkType}`" :article="article">
    <slot />
  </Component>
</template>

<script>
import ArticleLinkInternal from '@/components/UserInterface/Link/ArticleLinkInternal'
import ArticleLinkExternal from '@/components/UserInterface/Link/ArticleLinkExternal'

export default {
  components: {
    ArticleLinkInternal,
    ArticleLinkExternal,
  },
  props: {
    article: {
      type: Object,
      required: true,
    },
  },
  computed: {
    linkType() {
      const { teaser } = this.article.attributes
      return !teaser || !teaser.link ? 'Internal' : 'External'
    },
  },
}
</script>
