<template>
  <div class="footer-navigation">
    <FooterNavigationCategory
      v-for="(navCategory, index) in footerNavigation"
      :key="index"
      :nav-category="navCategory"
    />
  </div>
</template>

<script>
import FooterNavigationCategory from '@/components/Footer/FooterNavigationCategory'

export default {
  components: {
    FooterNavigationCategory,
  },
  computed: {
    footerNavigation() {
      return this.$store.getters['navigation/footerNavigation']
    },
  },
}
</script>

<style lang="scss">
@import 'assets/scss/import/vars';
@import 'assets/scss/import/mixins';

.footer-navigation {
  padding: 20px 0 30px;

  @media (min-width: $screen-width-1023) {
    display: flex;
  }
}
</style>
