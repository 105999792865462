<template>
  <div class="footer-media">
    <NuxtLink to="/" class="footer-media__logo">
      <WeidwerkLogoLight />
    </NuxtLink>
    <div class="footer-media__social-links">
      <a :href="urlFacebook" target="_blank">
        <IconFacebook />
      </a>
      <a :href="urlInstagram" target="_blank">
        <IconInstagram />
      </a>
      <a :href="urlYoutube" target="_blank">
        <IconYoutube />
      </a>
    </div>
  </div>
</template>

<script>
import WeidwerkLogoLight from '@/components/UserInterface/Icon/weidwerk-logo-light.svg'
import IconFacebook from '@/components/UserInterface/Icon/icon-social-facebook-white.svg'
import IconInstagram from '@/components/UserInterface/Icon/icon-social-instagram-white.svg'
import IconYoutube from '@/components/UserInterface/Icon/icon-social-youtube-white.svg'

export default {
  components: {
    WeidwerkLogoLight,
    IconFacebook,
    IconInstagram,
    IconYoutube,
  },
  computed: {
    urlFacebook() {
      return 'https://www.facebook.com/weidwerk.at/'
    },
    urlInstagram() {
      return 'https://www.instagram.com/weidwerk.at/'
    },
    urlYoutube() {
      return 'https://www.youtube.com/channel/UCZyzBRALZw8AWXDGw3wut3g'
    },
  },
}
</script>

<style lang="scss">
@import 'assets/scss/import/vars';
@import 'assets/scss/import/mixins';

.footer-media {
  margin-top: 10px;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  @media (min-width: $screen-width-1023) {
    flex-direction: row;
    justify-content: space-between;
    margin-top: 36px;
    padding-bottom: 10px;
  }

  &__logo {
    padding-top: 20px;
    line-height: 100%;

    @media (min-width: $screen-width-1023) {
      padding: 0;
    }

    svg {
      box-sizing: content-box;
      width: 150px;

      @media (min-width: $screen-width-1023) {
        width: auto;
      }
    }
  }

  &__social-links {
    display: flex;
    align-self: stretch;
    justify-content: center;
    padding-bottom: 10px;
    border-bottom: 1px solid $color-gray;

    @media (min-width: $screen-width-1023) {
      padding: 0;
      border-bottom: none;
    }

    a {
      box-sizing: content-box;
      line-height: 100%;
      transition: opacity ease-in 100ms;
      padding: 10px;

      @media (min-width: $screen-width-1023) {
        padding: 0;
        margin-left: 20px;
      }

      svg {
        height: 44px;
        width: 44px;

        @media (min-width: $screen-width-1023) {
          height: 36px;
          width: 36px;
        }
      }

      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }
  }
}
</style>
