<template>
  <ArticleLink v-if="isCurrentMagazineLink" :article="navItem.attributes.link.relationships.article">
    <ImageCaption
      :image="navItem.attributes.link.relationships.article.attributes.images[0]"
      :width="316"
      :height="416"
      :mobile-width="316"
      :mobile-height="416"
      mode="crop"
      class="navigation-link__image"
    />
  </ArticleLink>
  <ArticleLink v-else-if="isArticleLink" :article="navItem.attributes.link.relationships.article">
    {{ linkText }}
  </ArticleLink>
  <ChannelLink v-else-if="isChannelLink" :channel="navItem.attributes.link.relationships.channel">
    {{ linkText }}
  </ChannelLink>
  <a
    v-else-if="isHyperlink"
    :href="navItem.attributes.link.attributes.url"
    :target="navItem.attributes.link.attributes.target"
  >
    {{ linkText }}
  </a>
  <span v-else>
    {{ linkText }}
  </span>
</template>

<script>
import ArticleLink from '@/components/UserInterface/Link/ArticleLink'
import ChannelLink from '@/components/UserInterface/Link/ChannelLink'
import ImageCaption from '@/components/UserInterface/Image/ImageCaption'

export default {
  components: {
    ImageCaption,
    ArticleLink,
    ChannelLink,
  },
  props: {
    navItem: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isArticleLink() {
      return this.navItem.attributes.link.type === 'ContentLink' && this.navItem.attributes.link.relationships.article
    },
    isChannelLink() {
      return this.navItem.attributes.link.type === 'ChannelLink'
    },
    isHyperlink() {
      return this.navItem.attributes.link.attributes.url
    },
    linkText() {
      return this.navItem.attributes.link.attributes.text
    },
    isCurrentMagazineLink() {
      return (
        this.navItem.attributes.link.attributes.cssClasses.length > 0 &&
        this.navItem.attributes.link.relationships.article.attributes.images.length > 0 &&
        this.navItem.attributes.link.attributes.cssClasses.includes('current-magazine')
      )
    },
  },
}
</script>

<style lang="scss">
@import 'assets/scss/import/vars';

.navigation-link {
  &__image {
    max-height: 208px;
    max-width: 158px;
  }
}
</style>
