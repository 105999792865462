/**
 * Allows components to display shadow overlay over the whole page and
 * prevents user from scrolling the page while overlay effect is active.
 * This is particularly useful for modal dialogs and alike.
 */
export default {
  methods: {
    displayPageOverlay() {
      document.body.classList.add('shadow-overlay')
    },
    removePageOverlay() {
      document.body.classList.remove('shadow-overlay')
    },
  },
}
