<template>
  <div class="navigation-menu">
    <div class="navigation-menu__header">
      <div class="navigation-menu__menu-back" @click="tapBack">
        <IconBack v-show="selected.length > 0" />
      </div>
      <div class="navigation-menu__menu-title">
        <h3>
          {{ menuTitle }}
        </h3>
      </div>
      <div />
    </div>
    <ul role="menu" class="navigation-menu__menu">
      <li v-for="(navItem, index) in menu" :key="index" role="menuitem">
        <NavigationLink :nav-item="navItem" @click.native="clicked(navItem)" />
        <ChevronRight
          v-if="hasChildren(navItem)"
          class="navigation-menu__more"
          @click="hasChildren(navItem) ? tapSubmenu(index) : null"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import IconBack from '@/components/UserInterface/Icon/icon-arrow-left-black.svg'
import ChevronRight from '@/components/UserInterface/Icon/icon-chevron-right-gray.svg'
import NavigationLink from '@/components/UserInterface/Link/NavigationLink'

export default {
  components: {
    IconBack,
    ChevronRight,
    NavigationLink,
  },
  props: {
    mobileMenuOpened: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    menuTitle: null,
    menu: null,
    selected: [],
  }),
  computed: {
    headerNavigation() {
      return this.$store.getters['navigation/headerNavigation']
    },
  },
  watch: {
    mobileMenuOpened: {
      immediate: true,
      handler(newValue) {
        if (newValue === false) {
          this.selected = []
        }
      },
    },
    selected: {
      immediate: true,
      handler(currentSelections) {
        let newMenuTitle = 'Menu'
        let newMenu = this.headerNavigation

        currentSelections.forEach((key) => {
          newMenuTitle = newMenu[key].attributes.link.attributes.text
          newMenu = newMenu[key].attributes.children
        })

        this.menuTitle = newMenuTitle
        this.menu = newMenu
      },
    },
  },
  methods: {
    tapSubmenu(menuKey) {
      if (this.mobileMenuOpened) {
        this.selected.push(menuKey)
      }
    },
    tapBack() {
      this.selected.pop()
    },
    hasChildren(navItem) {
      return navItem.attributes.children && navItem.attributes.children.length > 0
    },
    clicked($navItem) {
      if ($navItem.attributes.link.attributes.text === 'Shop/Abo') {
        this.$fb.query('trackCustom', 'shop-abo-clicked')
      }
    },
  },
}
</script>

<style lang="scss">
@import 'assets/scss/import/vars';
@import 'assets/scss/import/mixins';

.navigation-menu {
  height: 100%;
  background-color: $color-white;
  flex: 1;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $color-gray-light;
    height: 50px;
    padding: 0 15px;

    @media (min-width: $screen-width-767) {
      height: 100px;
    }

    @media (min-width: $screen-width-1023) {
      display: none;
    }

    div {
      display: flex;
      flex: 1;
    }
  }

  &__menu-back {
    svg {
      box-sizing: content-box;
      width: 20px;
      height: 14px;
      padding: 10px 30px 10px 0;

      @media (min-width: $screen-width-767) {
        padding: 20px 50px 20px 0;
      }
    }
  }

  &__menu-title {
    justify-content: center;

    h3 {
      @include semibold-source-sans-pro;

      text-align: center;
      font-size: 18px;
      letter-spacing: 2px;
      line-height: 25px;
      margin: 0;
      text-transform: uppercase;
    }
  }

  &__menu {
    overflow: auto;
    height: calc(100vh - 50px);

    @media (min-width: $screen-width-767) {
      height: calc(100vh - 100px);
    }

    @media (min-width: $screen-width-1023) {
      overflow: visible;
      height: auto;
      display: flex;
      width: 100%;
      margin: 0;
      padding-left: 20px;
    }

    li {
      @include semibold-source-sans-pro;

      list-style: none;
      border-bottom: 1px solid $color-gray-light;
      color: $color-brown-dark;
      font-size: 18px;
      text-transform: uppercase;
      padding: 0 15px;
      height: 60px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (min-width: $screen-width-767) {
        height: 70px;
      }

      @media (min-width: $screen-width-1023) {
        font-size: 16px;
        border: none;
      }

      a {
        color: $color-brown-dark;
        padding: 16px 0;
        width: 100%;

        @media (min-width: $screen-width-767) {
          padding: 20px 0;
        }

        @media (min-width: $screen-width-1023) {
          padding: 16px 28px;
          transition: color 300ms;

          &:hover {
            color: $color-brown;
          }
        }
      }

      a.nuxt-link-active {
        @media (min-width: $screen-width-1023) {
          color: $color-brown;
        }
      }

      &::before {
        display: none;
      }
    }
  }

  &__more {
    box-sizing: content-box;
    padding: 23px 0 23px 70px;
    cursor: pointer;

    @media (min-width: $screen-width-767) {
      padding: 28px 0 28px 120px;
    }

    @media (min-width: $screen-width-1023) {
      display: none;
    }
  }
}
</style>
