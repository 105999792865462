<template>
  <div>
    <Header />
    <main class="page">
      <Nuxt />
    </main>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header/Header'
import Footer from '@/components/Footer/Footer'

export default {
  components: {
    Header,
    Footer,
  },
}
</script>

<style lang="scss">
@import 'assets/scss/import/vars';

.page {
  width: 100%;
  max-width: $max-width-page;
  margin: auto;
}
</style>
