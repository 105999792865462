import { VENDOR_ANALYTICS } from '@/store/consent'

let loadPixel = null

const loadAnalytics = ($config, $fb) => {
  if (!$config.pixelId) {
    // eslint-disable-next-line no-console
    console.warn('Meta pixel id is not available in this environment')
    return
  }

  if (loadPixel) {
    return loadPixel
  }

  $fb.enable()

  loadPixel = true
}

export const actions = {
  init({ rootGetters }) {
    /*
     * With GA-4 properties our only job is to load the script. Property will automatically watch browser
     * history to handle client router navigations. Property will also respect the consent settings
     * stored in "window.dataLayer" which are automatically updated by the UC. By not loading the script
     * at all for users who opt out of tracking we can speed the experience up.
     * */
    this.watch(
      () => rootGetters['consent/hasVendorConsent'](VENDOR_ANALYTICS),
      (hasConsent) => {
        hasConsent && loadAnalytics(this.$config, this.$fb)
      }
    )
  },
}
