import { set } from 'vue'
import ARTICLES from '@/gql/articles.gql'

export const state = () => ({
  newsArticles: null,
  isCached: false,
})

export const mutations = {
  SET(state, data) {
    set(state, 'newsArticles', data)
    set(state, 'isCached', true)
  },
}

export const actions = {
  async fetch({ commit, state }) {
    if (state.isCached) return
    try {
      const client = this.app.apolloProvider.defaultClient

      const newsArticles = await client.query({
        query: ARTICLES,
        variables: {
          portalId: this.$config.portalId,
          filter_channelId: this.$config.channelIdNews,
          order: '-frontendDate',
          limit: 10,
        },
      })

      commit('SET', newsArticles.data.getArticlesByPortalId.data)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  },
}

export const getters = {
  newsArticles({ newsArticles }) {
    return newsArticles || []
  },
}
