import { loadScript } from '@/utils/scripts'

const isCmpAvailable = () => {
  const isCmpAvailable = !!(process.client && window.UC_UI)

  if (!isCmpAvailable) {
    // eslint-disable-next-line no-console
    console.warn('Consent management is not available in this environment.')
  }

  return isCmpAvailable
}

export const VENDOR_ANALYTICS = 'Analytics'
export const VENDOR_YOUTUBE = 'Youtube'
export const VENDOR_INSTAGRAM = 'Instagram'
export const VENDOR_SPOTIFY = 'Spotify'

export const state = () => ({
  [VENDOR_ANALYTICS]: false,
  [VENDOR_YOUTUBE]: false,
  [VENDOR_INSTAGRAM]: false,
  [VENDOR_SPOTIFY]: false,
})

export const mutations = {
  [`SET_CONSENT_${VENDOR_ANALYTICS}`]: (state, value) => (state[VENDOR_ANALYTICS] = value),
  [`SET_CONSENT_${VENDOR_YOUTUBE}`]: (state, value) => (state[VENDOR_YOUTUBE] = value),
  [`SET_CONSENT_${VENDOR_INSTAGRAM}`]: (state, value) => (state[VENDOR_INSTAGRAM] = value),
  [`SET_CONSENT_${VENDOR_SPOTIFY}`]: (state, value) => (state[VENDOR_SPOTIFY] = value),
}

export const actions = {
  async init({ dispatch }) {
    if (this.loadConsentPromise) {
      return this.loadConsentPromise
    }

    if (this.$config.usercentrics.id) {
      try {
        await Promise.all([
          loadScript({
            id: 'usercentrics-cmp',
            src: 'https://app.usercentrics.eu/browser-ui/latest/loader.js',
            async: true,
            dataset: { settingsId: this.$config.usercentrics.id },
          }),
          new Promise((resolve) => {
            window.addEventListener('UC_UI_INITIALIZED', resolve)
          }),
        ])
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
        this.loadConsentPromise = Promise.resolve(false)
        return this.loadConsentPromise
      }

      window.addEventListener('ucEvent', (event) => {
        if (event.detail.event === 'consent_status') {
          dispatch('loadConsentState')
        }
      })

      this.loadConsentPromise = dispatch('loadConsentState')
      dispatch('hidePrivacyButton')
    }

    return this.loadConsentPromise
  },
  loadConsentState({ commit }) {
    if (isCmpAvailable()) {
      const vendorMap = {
        [this.$config.usercentrics.vendorIds.analytics]: VENDOR_ANALYTICS,
        [this.$config.usercentrics.vendorIds.youtube]: VENDOR_YOUTUBE,
        [this.$config.usercentrics.vendorIds.instagram]: VENDOR_INSTAGRAM,
        [this.$config.usercentrics.vendorIds.spotify]: VENDOR_SPOTIFY,
      }
      window.UC_UI.getServicesBaseInfo().forEach((service) => {
        const vendor = vendorMap[service.id]

        if (vendor) {
          commit(`SET_CONSENT_${vendor}`, service.consent.status)
        }
      })
    }
  },
  async hidePrivacyButton({ dispatch }) {
    await dispatch('init')

    if (isCmpAvailable()) {
      const usercentricsRoot = document.querySelector('#usercentrics-root').shadowRoot
      const styleElement = document.createElement('style')
      styleElement.innerText = 'button[data-testid="uc-privacy-button"] { display: none !important; }'
      usercentricsRoot.appendChild(styleElement)
    }
  },
  async openMenu({ dispatch }) {
    await dispatch('init')

    if (isCmpAvailable()) {
      window.UC_UI.showSecondLayer()
    }
  },
  async giveVendorConsent({ dispatch }, vendor) {
    await dispatch('init')

    if (isCmpAvailable()) {
      const vendorMapReverse = {
        [VENDOR_ANALYTICS]: this.$config.usercentrics.vendorIds.analytics,
        [VENDOR_YOUTUBE]: this.$config.usercentrics.vendorIds.youtube,
        [VENDOR_INSTAGRAM]: this.$config.usercentrics.vendorIds.instagram,
        [VENDOR_SPOTIFY]: this.$config.usercentrics.vendorIds.spotify,
      }

      if (!vendorMapReverse[vendor]) {
        // eslint-disable-next-line no-console
        console.warn(`Vendor "${vendor}" is missing consent configuration in the client.`)
      }

      window.UC_UI.acceptService(vendorMapReverse[vendor])
    }
  },
  /*
    This asynchronous action returns the consent value only after the consent tool is loaded. This
    is important for properly binding non-reactive parts of the app to the consent (especially those firing early,
    f.e. tracking scripts).
  */
  async hasVendorConsent({ dispatch, state }, vendor) {
    try {
      await dispatch('init')
      return state[vendor]
    } catch (e) {
      return false
    }
  },
}

export const getters = {
  /*
    This getter returns the current consent value from the state, which defaults
    to false if consent tool is not yet loaded. This is useful for binding reactive parts of the app to the consent
    (for example vue templates which require consent - media embeds etc.).
  */
  hasVendorConsent: (state) => (vendor) => state[vendor],
}
