<template>
  <div class="footer-banner">
    <p class="footer-banner__copyright">
      {{ copyrightText }}
    </p>
    <button class="footer-banner__consent-opener" @click="$store.dispatch('consent/openMenu')">
      Cookie Einstellung
    </button>
  </div>
</template>

<script>
export default {
  computed: {
    copyrightText() {
      return `© ${new Date().getFullYear()} Österreichs WEIDWERK: Magazin für Jagd, Fischerei, Natur- und Umweltschutz.`
    },
  },
}
</script>

<style lang="scss">
@import 'assets/scss/import/vars';

.footer-banner {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  padding: 20px 0 25px 0;

  @media (min-width: $screen-width-1023) {
    height: 96px;
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
    padding: 0;
  }

  &__copyright {
    font-size: 14px;
    color: $color-text-light;
    margin: 10px 0 0 0;
    line-height: 18px;
    text-align: center;

    @media (min-width: $screen-width-1023) {
      margin: 0;
    }
  }

  &__consent-opener {
    color: $color-text-light;
    background-color: transparent;
    padding: 10px 0;
    margin-bottom: 10px;
    transition: color 250ms;
    font-size: 14px;

    @media (min-width: $screen-width-1023) {
      margin-bottom: 0;
    }

    &:hover {
      color: $color-text-very-light;
    }
  }
}
</style>
