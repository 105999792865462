<template>
  <nav class="header-navigation" :aria-expanded="mobileMenuOpened || desktopMenuActive ? 'true' : 'false'">
    <OpenMenuButton class="header-navigation__open-button" @click="openMobileMenu" />
    <div v-show="mobileMenuOpened" class="header-navigation__menu-wrapper">
      <HeaderNavigationMenu :mobile-menu-opened="mobileMenuOpened" @item-clicked="closeMobileMenu" />
      <CloseMenuButton class="header-navigation__close-button" @click="closeMobileMenu" />
    </div>
  </nav>
</template>

<script>
import OpenMenuButton from '@/components/UserInterface/Icon/icon-menu.svg'
import CloseMenuButton from '@/components/UserInterface/Icon/icon-close.svg'
import PageOverlayHandler from '@/utils/mixins/page-overlay-handler.js'
import HeaderNavigationMenu from '@/components/Header/HeaderNavigationMenu'

export default {
  components: {
    OpenMenuButton,
    HeaderNavigationMenu,
    CloseMenuButton,
  },
  mixins: [PageOverlayHandler],
  data: () => ({
    mobileMenuOpened: false,
    desktopMenuActive: false,
  }),
  mounted() {
    if (process.browser) {
      window.addEventListener('resize', this.handleResize)
      this.desktopMenuActive = window.innerWidth > 1023
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    openMobileMenu() {
      this.mobileMenuOpened = true
      this.displayPageOverlay()
    },
    closeMobileMenu() {
      this.mobileMenuOpened = false
      this.removePageOverlay()
    },
    handleResize() {
      if (window.innerWidth > 1023) {
        this.closeMobileMenu()
        this.desktopMenuActive = true
      } else {
        this.desktopMenuActive = false
      }
    },
  },
}
</script>

<style lang="scss">
@import 'assets/scss/import/vars';

.header-navigation {
  display: flex;
  width: 100%;

  &__open-button {
    box-sizing: content-box;
    padding: 15px 50px 15px 0;

    @media (min-width: $screen-width-767) {
      padding: 30px 70px 30px 0;
    }

    @media (min-width: $screen-width-1023) {
      display: none;
    }
  }

  @media (min-width: $screen-width-767) {
    svg.header-navigation__open-button {
      height: 24px;
      width: 24px;
    }
  }

  &__close-button {
    padding: 17px;
    width: 50px;
    height: 51px;
    background-color: $color-gray-dark;

    @media (min-width: $screen-width-767) {
      width: 100px;
      height: 101px;
      padding: 42px;
    }

    @media (min-width: $screen-width-1023) {
      display: none;
    }
  }

  &__menu-wrapper {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10;

    @media (min-width: $screen-width-1023) {
      display: block !important;
      position: static;
      height: auto;
      z-index: auto;
      width: 100%;
    }
  }
}
</style>
