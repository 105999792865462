<template>
  <NuxtLink :to="route" class="link-article">
    <slot>
      {{ article.attributes.title }}
    </slot>
  </NuxtLink>
</template>

<script>
export default {
  props: {
    article: {
      type: Object,
      required: true,
    },
  },
  computed: {
    channelSlugs() {
      return this.$parseChannelSlugs(this.article.relationships.channels, this.$config)
    },
    route() {
      const { textType, serieTitle, pageTitle } = this.article.attributes

      const isSerie = textType === 'Episode'
      const serieSlug = isSerie && this.$slugify(serieTitle)
      const articleSlug = this.$slugify(pageTitle)

      return {
        name: 'article-canonical',
        params: {
          id: this.article.id,
          channelSlugs: isSerie ? [...this.channelSlugs, serieSlug] : this.channelSlugs,
          slug: articleSlug,
          isSerie,
        },
      }
    },
  },
}
</script>
