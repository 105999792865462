export default function (to, from, savedPosition) {
  if (from.params.isSerie || to.params.isSerie) {
    let fromChannelSlugs = from.params.channelSlugs || []
    let toChannelSlugs = to.params.channelSlugs || []

    fromChannelSlugs = Array.isArray(fromChannelSlugs) ? fromChannelSlugs : fromChannelSlugs.split('/')
    toChannelSlugs = Array.isArray(toChannelSlugs) ? toChannelSlugs : toChannelSlugs.split('/')

    if (fromChannelSlugs[fromChannelSlugs.length - 1] === toChannelSlugs[toChannelSlugs.length - 1]) {
      return savedPosition
    }
  }

  return { x: 0, y: 0 }
}
