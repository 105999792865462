import { VENDOR_ANALYTICS } from '@/store/consent'
import { loadScript } from '@/utils/scripts'

let loadAnalyticsPromise = null

const loadAnalytics = ($config) => {
  if (!$config.analyticsId) {
    // eslint-disable-next-line no-console
    console.warn('Google analytics is not available in this environment')
    return
  }

  if (loadAnalyticsPromise) {
    return loadAnalyticsPromise
  }

  window.dataLayer = window.dataLayer || []
  function gtag() {
    window.dataLayer.push(arguments)
  }
  gtag('js', new Date())
  gtag('config', $config.analyticsId)

  loadAnalyticsPromise = loadScript({
    src: `https://www.googletagmanager.com/gtag/js?id=${$config.analyticsId}`,
    async: true,
  })

  return loadAnalyticsPromise
}

export const actions = {
  init({ rootGetters }) {
    /*
     * With GA-4 properties our only job is to load the script. Property will automatically watch browser
     * history to handle client router navigations. Property will also respect the consent settings
     * stored in "window.dataLayer" which are automatically updated by the UC. By not loading the script
     * at all for users who opt out of tracking we can speed the experience up.
     * */
    this.watch(
      () => rootGetters['consent/hasVendorConsent'](VENDOR_ANALYTICS),
      (hasConsent) => {
        hasConsent && loadAnalytics(this.$config)
      }
    )
  },
}
