<template>
  <li class="footer-navigation-item" role="menuitem" @click="clicked(navItem)">
    <NavigationLink :nav-item="navItem" />
  </li>
</template>

<script>
import NavigationLink from '@/components/UserInterface/Link/NavigationLink'

export default {
  components: {
    NavigationLink,
  },
  props: {
    navItem: {
      type: Object,
      required: true,
    },
  },
  methods: {
    clicked($navItem) {
      if ($navItem.attributes.link.attributes.text === 'Kontakt') {
        this.$fb.query('trackCustom', 'contact-clicked')
      }
    },
  },
}
</script>

<style lang="scss">
@import 'assets/scss/import/vars';
@import 'assets/scss/import/mixins';

.footer-navigation-item {
  font-size: 16px;
  letter-spacing: 1.33px;
  color: $color-white;
  padding: 0;
  display: flex;

  &:first-child {
    margin-top: 10px;

    @media (min-width: $screen-width-1023) {
      margin-top: 0;
    }
  }

  a {
    @include hovering-translate-right;

    width: 100%;
    padding: 12px 0;
    color: $color-white;

    @media (min-width: $screen-width-1023) {
      width: auto;
      padding: 5px 20px 5px 0;
    }
  }

  &::before {
    display: none;
  }
}
</style>
