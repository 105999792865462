import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _08cab5f4 = () => interopDefault(import('../pages/agb.vue' /* webpackChunkName: "pages/agb" */))
const _42a05aa5 = () => interopDefault(import('../pages/anzeige-buchen.vue' /* webpackChunkName: "pages/anzeige-buchen" */))
const _0a9a70a6 = () => interopDefault(import('../pages/datenschutz.vue' /* webpackChunkName: "pages/datenschutz" */))
const _112df016 = () => interopDefault(import('../pages/downloads.vue' /* webpackChunkName: "pages/downloads" */))
const _94052cfe = () => interopDefault(import('../pages/impressum.vue' /* webpackChunkName: "pages/impressum" */))
const _43251f6c = () => interopDefault(import('../pages/jagdkalender.vue' /* webpackChunkName: "pages/jagdkalender" */))
const _55718208 = () => interopDefault(import('../pages/liefer-und-zahlungsbedingungen.vue' /* webpackChunkName: "pages/liefer-und-zahlungsbedingungen" */))
const _c42b8c5c = () => interopDefault(import('../pages/partner.vue' /* webpackChunkName: "pages/partner" */))
const _49454391 = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _1cc05a04 = () => interopDefault(import('../pages/termine.vue' /* webpackChunkName: "pages/termine" */))
const _cf230694 = () => interopDefault(import('../pages/ueber-uns.vue' /* webpackChunkName: "pages/ueber-uns" */))
const _7f173548 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _5f6d30ab = () => interopDefault(import('../pages/article/_id.vue' /* webpackChunkName: "pages/article/_id" */))
const _e891c1c8 = () => interopDefault(import('../pages/author/_id.vue' /* webpackChunkName: "pages/author/_id" */))
const _12c9cc08 = () => interopDefault(import('../pages/channel/_slug.vue' /* webpackChunkName: "pages/channel/_slug" */))
const _7f6bc84f = () => interopDefault(import('../pages/tag/_id.vue' /* webpackChunkName: "pages/tag/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/agb",
    component: _08cab5f4,
    name: "agb"
  }, {
    path: "/anzeige-buchen",
    component: _42a05aa5,
    name: "anzeige-buchen"
  }, {
    path: "/datenschutz",
    component: _0a9a70a6,
    name: "datenschutz"
  }, {
    path: "/downloads",
    component: _112df016,
    name: "downloads"
  }, {
    path: "/impressum",
    component: _94052cfe,
    name: "impressum"
  }, {
    path: "/jagdkalender",
    component: _43251f6c,
    name: "jagdkalender"
  }, {
    path: "/liefer-und-zahlungsbedingungen",
    component: _55718208,
    name: "liefer-und-zahlungsbedingungen"
  }, {
    path: "/partner",
    component: _c42b8c5c,
    name: "partner"
  }, {
    path: "/search",
    component: _49454391,
    name: "search"
  }, {
    path: "/termine",
    component: _1cc05a04,
    name: "termine"
  }, {
    path: "/ueber-uns",
    component: _cf230694,
    name: "ueber-uns"
  }, {
    path: "/",
    component: _7f173548,
    name: "index"
  }, {
    path: "/article/:id?",
    component: _5f6d30ab,
    name: "article-id"
  }, {
    path: "/author/:authorName([a-z0-9A-F%-]+)-:id(\\d+)",
    component: _e891c1c8,
    name: "author-id"
  }, {
    path: "/channel/:slug?",
    component: _12c9cc08,
    name: "channel-slug"
  }, {
    path: "/tag/:tagName([a-z0-9A-F%-]+)-:id(\\d+)",
    component: _7f6bc84f,
    name: "tag-id"
  }, {
    path: "/:channelSlugs([a-z0-9A-F%-]+)+/:slug([a-z0-9A-F%-]+)-:id(\\d+)",
    component: _5f6d30ab,
    name: "article-canonical"
  }, {
    path: "/:channelSlugs([a-z0-9A-F%-]+)*/:slug([a-z0-9A-F%-]+)",
    component: _12c9cc08,
    name: "channel-canonical"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
