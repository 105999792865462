<template>
  <footer class="page-footer">
    <div class="page-footer__content">
      <FooterMedia />
      <FooterNavigation />
    </div>
    <div class="page-footer__banner">
      <div class="page-footer__content">
        <FooterBanner />
      </div>
    </div>
  </footer>
</template>

<script>
import FooterMedia from '@/components/Footer/FooterMedia'
import FooterNavigation from '@/components/Footer/FooterNavigation'
import FooterBanner from '@/components/Footer/FooterBanner'

export default {
  components: {
    FooterMedia,
    FooterNavigation,
    FooterBanner,
  },
}
</script>

<style lang="scss">
@import 'assets/scss/import/vars';

.page-footer {
  margin: auto;
  background-color: $color-black-light;
  border-top: 4px solid $color-brown;

  &__content {
    max-width: $max-width-content;
    margin: auto;
    padding: 0 15px;

    @media (min-width: $screen-width-1300 + 50px) {
      padding: 0;
    }
  }

  &__banner {
    background-color: $color-black-soft;
  }
}
</style>
