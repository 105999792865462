<template>
  <div class="nuxt-error">
    <h1 class="nuxt-error__headline">
      {{ headline }}
    </h1>
    <p v-if="error.statusCode === 404" class="nuxt-error__message">
      Die aufgerufene Seite konnte nicht gefunden werden.
    </p>
    <ButtonLink :link="{ path: '/' }" class="nuxt-error__homelink"> zur Startseite </ButtonLink>
  </div>
</template>

<script>
import ButtonLink from '@/components/UserInterface/Button/ButtonLink'

export default {
  components: {
    ButtonLink,
  },
  props: {
    error: {
      type: Object,
      required: true,
    },
  },
  computed: {
    headline() {
      return this.error.statusCode === 404 ? '404' : 'Ein Fehler ist aufgetreten'
    },
  },
}
</script>

<style lang="scss">
@import 'assets/scss/import/vars';

.nuxt-error {
  text-align: center;
  margin: 80px 0 80px 0;

  &__headline {
    margin-bottom: 10px;
  }

  &__message {
    color: $color-red;
    margin: 0;
  }

  &__homelink {
    margin-top: 30px;
  }
}
</style>
