import { set } from 'vue'
import NAVIGATION from '@/gql/navigation.gql'

export const state = () => ({
  navigation: null,
  isCached: false,
})

export const mutations = {
  SET(state, data) {
    set(state, 'navigation', data)
    set(state, 'isCached', true)
  },
}

export const actions = {
  async fetch({ commit, state }) {
    if (state.isCached) return
    try {
      const client = this.app.apolloProvider.defaultClient
      const { data } = await client.query({
        query: NAVIGATION,
        variables: {
          portalId: parseInt(this.$config.portalId),
        },
      })
      commit('SET', data.getNavigationItemsByPortalId.data[0])
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  },
}

export const getters = {
  headerNavigation({ navigation }) {
    const headerNavigation = navigation.attributes.children.find(
      (child) => child.attributes.link.attributes.text === 'header'
    )
    return headerNavigation ? headerNavigation.attributes.children : []
  },
  footerNavigation({ navigation }) {
    const footerNavigation = navigation.attributes.children.find(
      (child) => child.attributes.link.attributes.text === 'footer'
    )
    return footerNavigation ? footerNavigation.attributes.children : []
  },
}
