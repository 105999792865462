import Vue from 'vue'
import Moment from 'moment'
import { getPortalChannel } from '@/plugins/url-utils'
Moment.locale('de')

/*
 ** format date filter
 */
Vue.filter('formatDate', function (value) {
  if (!value) {
    return ''
  }
  // FIXME: Need proper ISO value for dates
  // value = new Date(value) // prevent moment deprication warning
  return Moment(value).format('DD.MM.YYYY')
})

Vue.filter('getChannelName', function (value, $config) {
  if (!value) {
    return ''
  }
  const channel = getPortalChannel(value, $config)
  if (channel === null) return ''
  return channel.attributes.name
})
