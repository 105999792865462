import { set } from 'vue'

export const state = () => ({
  isInitialized: false,
  screenSize: null,
})

export const mutations = {
  INIT(state) {
    set(state, 'isInitialized', true)
  },
  SET(state, data) {
    state.screenSize = data
  },
}

export const actions = {
  init({ commit, state }) {
    if (state.isInitialized) {
      return
    }

    commit('INIT')
    const updateWidth = () => commit('SET', window.innerWidth)

    window.addEventListener('resize', updateWidth)
    window.addEventListener('load', updateWidth)
  },
}

export const getters = {
  isMobile({ screenSize }) {
    return screenSize ? screenSize < 1023 : null
  },
}
