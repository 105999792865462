<template>
  <SpunqImage v-bind="$props" class="caption-image">
    <figcaption class="caption-image__caption">
      <slot>
        {{ defaultCaption }}
      </slot>
    </figcaption>
  </SpunqImage>
</template>

<script>
import SpunqImage from '@/components/UserInterface/Image/SpunqImage'

export default {
  components: {
    SpunqImage,
  },
  props: {
    ...SpunqImage.props,
  },
  computed: {
    defaultCaption() {
      const { description, copyright } = this.image.attributes

      const desc = description || ''
      const copy = copyright ? `© ${copyright}` : ''
      return [desc, copy].filter((s) => !!s).join(' ')
    },
  },
}
</script>

<style lang="scss">
@import 'assets/scss/import/vars.scss';

.caption-image {
  margin-bottom: 1rem;

  &__caption {
    color: $color-text-light;
    font-size: 16px;
    line-height: 24px;
    margin-top: 5px;
  }
}
</style>
