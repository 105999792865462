export const actions = {
  async nuxtServerInit({ dispatch }) {
    await dispatch('navigation/fetch')
    await dispatch('channels/fetch')
    await dispatch('newsArticles/fetch')
  },
  async nuxtClientInit({ dispatch }) {
    await dispatch('analytics/init')
    await dispatch('pixel/init')
    await dispatch('consent/init')
    await dispatch('screenSize/init')
  },
}
