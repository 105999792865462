<template>
  <figure v-if="!!fileName" class="responsive-image">
    <picture v-if="lazy">
      <source
        ref="sourceMobile"
        :data-srcset="urlMobile"
        :srcset="mobilePlaceholder"
        :media="`(max-width: ${maxWidth}px)`"
      />
      <source ref="source" :data-srcset="url" :srcset="placeholder" :media="`(min-width: ${minWidth}px)`" />
      <img
        ref="image"
        :alt="alt || ''"
        :title="title || ''"
        :data-src="url"
        :src="placeholder"
        :style="style"
        :class="loading ? 'lazyload' : 'lazyloaded'"
      />
    </picture>
    <picture v-else>
      <source :srcset="urlMobile" :media="`(max-width: ${maxWidth}px)`" />
      <source :srcset="url" :media="`(min-width: ${minWidth}px)`" />
      <img :alt="alt || ''" :title="title || ''" :src="url" />
    </picture>
    <slot />
  </figure>
</template>

<script>
// from: https://markus.oberlehner.net/blog/lazy-loading-responsive-images-with-vue/
import lozad from 'lozad'

export default {
  name: 'ResponsiveImage',
  props: {
    baseUrls: {
      type: Array,
      required: true,
      validator(value) {
        return value && value.length > 0
      },
    },
    fileName: {
      type: String,
      required: true,
    },
    // https://github.com/strg-at/weidwerk-nuxt-weidwerk/pull/14/files#diff-be4625706b34ce2ab526d9caeb9acdb9fa8e736549e239dabca1b1b829d0486cL48-L56
    fileId: {
      type: String,
      default() {
        // Fallback-id generated from filename
        return this.fileName
          .split('')
          .map((c) => c.charCodeAt(0) - 64)
          .reduce((v, r) => v + r, 0)
      },
    },
    imageId: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      default: null,
    },
    alt: {
      type: String,
      default: null,
    },
    lazy: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    backgroundColor: {
      type: String,
      default: '#f5f5f5',
    },
    mobileWidth: {
      type: Number,
      default() {
        return this.width
      },
    },
    mobileHeight: {
      type: Number,
      default() {
        return this.height
      },
    },
    minWidth: {
      type: Number,
      default() {
        return typeof this.maxWidth === 'number' ? this.maxWidth + 1 : 768
      },
    },
    maxWidth: {
      type: Number,
      default() {
        return typeof this.minWidth === 'number' ? this.minWidth - 1 : 767
      },
    },
    mode: {
      type: String,
      default: null,
      validator(value) {
        /**
         * Define allowed modes
         */
        const modes = [null, 'crop']
        return modes.includes(value)
      },
    },
    observerOptions: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    baseUrl() {
      return this.baseUrls[this.imageId % this.baseUrls.length]
    },
    url() {
      return this.makeUrl()
    },
    urlMobile() {
      return this.makeUrl(this.mobileWidth, this.mobileHeight)
    },
    placeholder() {
      const width = Math.round(this.width / 10)
      const height = Math.round(this.height / 10)
      return this.makeUrl(width, height)
    },
    mobilePlaceholder() {
      const width = Math.round(this.mobileWidth / 10)
      const height = Math.round(this.mobileHeight / 10)
      return this.makeUrl(width, height)
    },
    transformationMode() {
      switch (this.mode) {
        case 'crop':
          return 'c'
        default:
          return ''
      }
    },
    src() {
      const src = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=='
      return src
    },
    aspectRatio() {
      // Calculate the aspect ratio of the image
      // if the width and the height are given.
      if (!this.width || !this.height) return null

      let isMobile = false
      if (process.browser) {
        if (window.innerWidth < 767) {
          isMobile = true
        } else {
          isMobile = false
        }
      }

      if (isMobile) {
        return (this.mobileHeight / this.mobileWidth) * 100
      } else {
        return (this.height / this.width) * 100
      }
    },
    style() {
      // https://markus.oberlehner.net/blog/lazy-loading-responsive-images-with-vue/
      // The background color is used as a
      // placeholder while loading the image.
      // You can use the dominant color of the
      // image to improve perceived performance.
      // See: https://manu.ninja/dominant-colors-for-lazy-loading-images/
      // const style = { backgroundColor: this.backgroundColor }
      const style = {}

      if (this.width) style.width = `${this.width}px`

      // If the image is still loading and an
      // aspect ratio could be calculated, we
      // apply the calculated aspect ratio by
      // using padding top.
      const applyAspectRatio = this.loading && this.aspectRatio
      if (applyAspectRatio) {
        // Prevent flash of unstyled image
        // after the image is loaded.
        style.height = 0
        // Scale the image container according
        // to the aspect ratio.
        style.paddingTop = `${this.aspectRatio}%`
      }
      return style
    },
  },
  mounted() {
    if (!this.lazy) {
      this.loading = false
      return
    }
    // As soon as the <img> element triggers
    // the `load` event, the loading state is
    // set to `false`, which removes the apsect
    // ratio we've applied earlier.
    // Change: Since we use a placeholder we need to check
    // for the lodaz callback
    const setLoadingState = () => {
      this.loading = !this.$refs.image.getAttribute('data-loaded')
    }

    const imageElement = this.$refs.image
    imageElement.addEventListener('load', setLoadingState)
    // We remove the event listener as soon as
    // the component is destroyed to prevent
    // potential memory leaks.
    this.$once('hook:destroyed', () => {
      imageElement.removeEventListener('load', setLoadingState)
    })

    // We initialize Lozad.js on each element individualy
    // since the lodaz picture functionality doesn't work with vue
    lozad(this.$refs.image, this.observerOptions).observe()
    lozad(this.$refs.source, this.observerOptions).observe()
    lozad(this.$refs.sourceMobile, this.observerOptions).observe()
  },
  methods: {
    /**
     * Generate URL for image service
     * @param {Number} width - Transformation width, defaults to this.width
     * @param {Number} height - Transformation height, defaults to this.height
     * @param {String} mode - Transformation modes crop ['c', 's', 'cN', ...]
     * @param {String} format - output format (jpg, png) - not implemented.
     */
    makeUrl(width, height, mode, format) {
      width = width || this.width
      height = height || this.height
      mode = mode || this.transformationMode
      const fileName = encodeURIComponent(this.fileName)
      /**
       * TODO: remove in future if condition and keep just else part
       * Added to support old and new format https://github.com/strg-at/weidwerk-nuxt-weidwerk/pull/12/files
       **/
      if (this.baseUrl.includes('images')) {
        return `${this.baseUrl}/${this.fileId}-${width}x${height}${mode || ''}-${fileName}`
      } else {
        const modeFormat = mode ? `-${mode}` : ''
        return `${this.baseUrl}/${width}x${height}${modeFormat}/${this.imageId}/${fileName}`
      }
    },
  },
}
</script>

<style lang="scss">
.responsive-image {
  width: 100%;

  img {
    width: 100%;
    display: block;
    max-width: 100%;
    max-height: 100%;
    height: auto;
    transition: filter 0.7s, transform 0.7s;
  }
}

.lazyloading,
.lazyload {
  filter: blur(10px);
}
</style>
